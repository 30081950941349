@import "@workflows/ui/index.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
